import * as React from 'react';
import { Link } from 'gatsby';
import { Layout } from '../components/layout';
import Seo from '../components/utils/seo';

// styles

// markup
const NotFoundPage = () => {
  return (
    <Layout secondaryMode={true} hideFooter={true}>
      <Seo title='Not Found'></Seo>
      <section>
        <div className='section-title'>
          <h2 style={{ marginTop: '70px' }}>Page not found</h2>
        </div>
        <div className='container content'>
          <p style={{ textAlign: 'center' }}>
            Sorry{' '}
            <span role='img' aria-label='Pensive emoji'>
              😔
            </span>{' '}
            we couldn’t find what you were looking for.
            <br />
            {process.env.NODE_ENV === 'development' ? (
              <>
                <br />
                Try creating a page in <code>src/pages/</code>.
                <br />
              </>
            ) : null}
            <br />
            <Link to='/'>Go home</Link>.
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
